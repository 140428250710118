/** @jsx jsx */
import { useState } from "react"
import { jsx, Button } from "theme-ui"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"
import PayPal from "../components/paypal"

const Dona = () => {
  const [tab, setTab] = useState(0)

  const GoBack = () => (
    <Button
      sx={{ variant: `button.default`, bg: `red`, mx: `auto`, mt: 3 }}
      onClick={() => setTab(0)}
    >
      Metodi di Pagamento
    </Button>
  )

  return (
    <Layout title="Dona" subtitle="Insieme siamo #PiùdiUnaRegione">
      <SEO title="Dona" description="Insieme siamo #PiùdiUnaRegione" />
      <Container sx={{ maxWidth: 890, textAlign: `justify` }}>
        <section sx={{ px: [4, null] }}>
          <p>
            Diventa anche tu protagonista del tuo territorio, perché il Veneto
            torni a essere <strong>#PiùdiUnaRegione</strong>.
          </p>
          <p>
            Il Veneto è più di una Regione perché è fatto di donne e di uomini
            forti, abituati a rimboccarsi le maniche e a fare da soli che oggi,
            però, hanno bisogno di superare, insieme, questa fase di profonda
            crisi.
          </p>
          <p>
            <strong>Siamo più di una Regione</strong> perché non ci rassegniamo
            a vedere i nostri figli emigrare per l’arretratezza culturale che
            non gli riconosce meriti e non dà loro spazi e fiducia.
          </p>
          <p>
            <strong>Siamo più di una Regione</strong> perché c'è una rete unica
            di associazioni così forte e avanzata sulla quale investire e con la
            quale condividere progetti.
          </p>
          <p>
            <strong>Siamo più di una Regione</strong>, perché crediamo che
            l'ambiente sia una risorsa e non un problema.
          </p>
          <p>
            <strong>Siamo più di una Regione</strong>, perché vogliamo che i
            lavoratori, gli studenti e i turisti si possano spostare in libertà,
            sicurezza, velocità e a costi bassi.
          </p>
          <p>
            Sono convinto che un altro Veneto sia necessario e per renderlo
            possibile c’è bisogno dell’aiuto, dell’energia e delle idee di
            tutti.
          </p>
          <p>
            Per costruire assieme il futuro di questa bellissima regione c’è
            bisogno di te.
          </p>
          <p>
            Il tuo contributo è prezioso per per realizzare le attività delle
            prossime settimane. Dona ora per sostenere la campagna elettorale di
            Arturo Lorenzoni.
          </p>
          <p>Tutti insieme possiamo fare la differenza.</p>
          <p>
            Un grazie a tutti quanti voi.
            <br />
            <span sx={{ fontWeight: 600, fontStyle: `italic` }}>
              Arturo Lorenzoni
            </span>
          </p>
          <p>
            <strong>Insieme siamo #PiùdiUnaRegione</strong>
          </p>
        </section>
        {tab === 0 && (
          <div sx={{ px: 2, textAlign: `center` }}>
            <h2>Puoi inviare una donazione mediante:</h2>
            <div
              sx={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-evenly`,
              }}
            >
              <Button
                sx={{
                  variant: `button.default`,
                  width: [`250px`, `200px`],
                  m: 2,
                }}
                onClick={() => {
                  setTab(1)
                  trackCustomEvent({
                    category: "DonationB",
                    action: "Click",
                  })
                }}
              >
                Bonifico
              </Button>
              <Button
                sx={{
                  variant: `button.default`,
                  width: [`250px`, `200px`],
                  m: 2,
                }}
                onClick={() => {
                  setTab(2)
                  trackCustomEvent({
                    category: "DonationP",
                    action: "Click",
                  })
                }}
              >
                PayPal
              </Button>
            </div>
          </div>
        )}
        {tab === 1 && (
          <div
            sx={{
              display: `flex`,
              flexDirection: `column`,
              variant: `donationBox`,
            }}
          >
            <h1
              sx={{
                color: `secondary`,
                textTransform: `uppercase`,
              }}
            >
              Bonifico
            </h1>
            <p>
              <b>BENEFICIARIO</b>: Roberto Breda Mandatario Elettorale di Arturo
              Lorenzoni
            </p>
            <p>
              <b>IBAN</b>: IT61C0538712100000003246113
            </p>
            <p>
              <b>CAUSALE</b>:
              <i>
                Contributo elettorale per Arturo Lorenzoni + indirizzo residenza
                del soggetto erogatore + email
              </i>
            </p>
          </div>
        )}
        {tab === 2 && <PayPal />}
        {tab !== 0 && <GoBack />}
      </Container>
    </Layout>
  )
}

export default Dona

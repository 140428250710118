/** @jsx jsx */
import {
  jsx,
  Input,
  Button,
  Label,
  Box,
  Alert,
  Close,
  Checkbox,
} from "theme-ui"
import { PayPalButton } from "react-paypal-button-v2"
import { useState } from "react"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import { isEmail, isDate } from "validator"
import Privacy from "./forms/privacy"
import SelfDeclaration from "./forms/self-declaration"

export default () => {
  const min = 0
  const max = 500
  const [amount, setAmount] = useState(0)
  const [step, setStep] = useState(0)
  const [error, setError] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [pob, setPob] = useState("")
  const [dob, setDob] = useState("")
  const [cf, setCf] = useState("")
  const [address, setAddress] = useState("")
  const [city, setCity] = useState("")
  const [zip, setZip] = useState("")
  const [email, setEmail] = useState("")
  const [disabled, setDisable] = useState(false)
  const [checkedPrivacy, setCheckedPrivacy] = useState(false)
  const [checkedDeclaration, setCheckedDeclaration] = useState(false)
  const [togglePrivacy, setTogglePrivacy] = useState(false)
  const [toggleDeclaration, setToggleDeclaration] = useState(false)
  const buttons = [
    { amount: 15, description: "Stampa un volantino" },
    { amount: 50, description: "Sostieni lo staff" },
    { amount: 90, description: "Concorri ad un evento elettorale" },
    { amount: 130, description: "Affiggi un manifesto" },
  ]

  const ErrorMessage = ({ error }) => (
    <Alert sx={{ bg: `secondary`, color: `red`, mb: 3, p: 3 }} mb={2}>
      {error}
      <Close ml="auto" mr={-2} onClick={() => setError("")} />
    </Alert>
  )
  return (
    <div
      sx={{
        variant: `donationBox`,
      }}
    >
      <h1
        sx={{
          color: `secondary`,
          textTransform: `uppercase`,
        }}
      >
        PayPal
      </h1>
      {step === 0 && (
        <div
          sx={{
            m: `auto`,
            maxWidth: 600,
          }}
        >
          {" "}
          <p>Seleziona quanto vuoi donare</p>
          <div
            sx={{
              m: `auto`,
              display: `flex`,
              alignItems: `flex-end`,
              justifyContent: `center`,
              flexWrap: `wrap`,
            }}
          >
            {buttons.map(({ amount, description }, index) => (
              <Button
                key={index}
                onClick={() => {
                  setAmount(amount)
                  setStep(1)
                }}
                sx={{
                  display: `flex`,
                  flexDirection: `column`,
                  alignItems: `center`,
                  textAlign: `center`,
                  bg: `white`,
                  width: `130px`,
                  m: 1,
                }}
              >
                <img
                  src={`/images/button-w-${index}.jpg`}
                  alt={`${amount} euro`}
                  width="75px"
                />
                <p sx={{ color: `black`, fontSize: 2, lineHeight: 1 }}>
                  {description}
                </p>
                <Box
                  sx={{
                    m: `auto`,
                    variant: `button.default`,
                    width: `80px`,
                    fontWeight: 700,
                    bg: `primary`,
                    color: `white`,
                  }}
                >
                  €{amount}
                </Box>
              </Button>
            ))}
          </div>
          <p sx={{ my: 4 }}>Oppure scegli un altro importo:</p>
          {error !== "" && <ErrorMessage error={error} />}
          <Label htmlFor="amount" sx={{ maxWidth: 500, textAlign: `center` }}>
            Importo in euro
          </Label>
          <Input
            sx={{
              variant: `input.default`,
            }}
            type="number"
            step="any"
            name="amount"
            id="amount"
            value={amount}
            onChange={e => setAmount(e.target.value)}
          />
          <Button
            sx={{ variant: `button.default`, m: `auto` }}
            onClick={() => {
              if (amount <= min) {
                setError("Importo non valido.")
              } else if (amount > max) {
                setError("Scegliere importo non superiore a 500 euro.")
              } else {
                setStep(1)
                setError("")
              }
            }}
          >
            Continua
          </Button>
        </div>
      )}
      {step === 1 && (
        <div
          sx={{
            m: `auto`,
            maxWidth: 600,
            width: `100%`,
          }}
        >
          <p>Inserisci i tuoi dati:</p>
          <p>
            <strong>Tutti i campi sono obbligatori</strong>
          </p>
          <form>
            <Label htmlFor="nome">Nome</Label>
            <Input
              type="text"
              name="nome"
              id="nome"
              sx={{
                variant: `input.default`,
              }}
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              required
            />
            <Label htmlFor="cognome">Cognome</Label>
            <Input
              type="text"
              name="cognome"
              id="cognome"
              sx={{
                variant: `input.default`,
              }}
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              required
            />
            <Label htmlFor="cognome">Luogo di Nascita</Label>
            <Input
              type="text"
              name="pob"
              id="pob"
              sx={{
                variant: `input.default`,
              }}
              value={pob}
              onChange={e => setPob(e.target.value)}
              required
            />
            <Label htmlFor="cognome">Data di Nascita</Label>
            <Input
              type="date"
              name="dob"
              id="dob"
              sx={{
                variant: `input.default`,
              }}
              value={dob}
              onChange={e => setDob(e.target.value)}
              required
            />
            <Label htmlFor="cognome">Codice Fiscale</Label>
            <Input
              type="text"
              name="cf"
              id="cf"
              sx={{
                variant: `input.default`,
              }}
              value={cf}
              onChange={e => setCf(e.target.value)}
              required
            />
            <Label htmlFor="indirizzo">Via/Piazza</Label>
            <Input
              type="text"
              name="indirizzo"
              id="indirizzo"
              sx={{
                variant: `input.default`,
              }}
              value={address}
              onChange={e => setAddress(e.target.value)}
              required
            />
            <Label htmlFor="citta">Città</Label>
            <Input
              type="text"
              name="citta"
              id="citta"
              sx={{
                variant: `input.default`,
              }}
              value={city}
              onChange={e => setCity(e.target.value)}
              required
            />
            <Label htmlFor="cap">CAP</Label>
            <Input
              type="text"
              name="cap"
              id="cap"
              sx={{
                variant: `input.default`,
              }}
              value={zip}
              onChange={e => setZip(e.target.value)}
              required
            />
            <Label htmlFor="email">Indirizzo E-Mail</Label>
            <Input
              type="email"
              name="email"
              id="email"
              sx={{
                variant: `input.default`,
              }}
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
            <div sx={{ display: `flex` }}>
              <Label htmlFor="privacy">
                <Checkbox
                  name="privacy"
                  id="privacy"
                  defaultChecked={checkedPrivacy}
                  onChange={() => setCheckedPrivacy(!checkedPrivacy)}
                  required
                />
                <div sx={{ ml: 3, textAlign: `left` }}>
                  Dichiaro di aver letto e presto il consenso al trattamento dei
                  dati personali sulla base della{" "}
                  <a href="/privacy/" target="_blank">
                    Privacy Policy
                  </a>
                </div>
              </Label>
              <Button
                type="button"
                sx={{
                  bg: `white`,
                  color: `text`,
                  fontStyle: `italic`,
                  textDecoration: `underline`,
                }}
                onClick={() => setTogglePrivacy(!togglePrivacy)}
              >
                ?
              </Button>
            </div>

            {togglePrivacy && (
              <Privacy
                setToggle={setTogglePrivacy}
                sx={{
                  bg: `secondary`,
                  color: `white`,
                  textAlign: `left`,
                  my: 3,
                }}
              />
            )}
            <div sx={{ display: `flex`, mt: 4 }}>
              <Label htmlFor="declaration">
                <Checkbox
                  name="declaration"
                  id="declaration"
                  defaultChecked={checkedDeclaration}
                  onChange={() => setCheckedDeclaration(!checkedDeclaration)}
                  required
                />
                <div sx={{ ml: 3, textAlign: `left` }}>
                  Autodichiarazione ai sensi della legge 3/2019
                </div>
              </Label>
              <Button
                type="button"
                sx={{
                  bg: `white`,
                  color: `text`,
                  fontStyle: `italic`,
                  textDecoration: `underline`,
                }}
                onClick={() => setToggleDeclaration(!toggleDeclaration)}
              >
                ?
              </Button>
            </div>

            {toggleDeclaration && (
              <SelfDeclaration
                setToggle={setToggleDeclaration}
                sx={{
                  bg: `secondary`,
                  color: `white`,
                  textAlign: `left`,
                }}
              />
            )}
            <div
              sx={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-around`,
                mt: 4,
              }}
            >
              <Button
                sx={{ variant: `button.default`, m: 2 }}
                onClick={() => {
                  setStep(0)
                }}
              >
                Torna Indietro
              </Button>
              <Button
                sx={{ variant: `button.default`, m: 2 }}
                disabled={
                  firstName &&
                  lastName &&
                  address &&
                  city &&
                  zip &&
                  cf &&
                  pob &&
                  isDate(dob) &&
                  isEmail(email) &&
                  checkedPrivacy &&
                  checkedDeclaration
                    ? false
                    : true
                }
                onClick={() => {
                  if (
                    firstName &&
                    lastName &&
                    address &&
                    city &&
                    zip &&
                    isEmail(email) &&
                    checkedPrivacy &&
                    checkedDeclaration
                  ) {
                    trackCustomEvent({
                      category: "DonationP2",
                      action: "Submit",
                    })
                    setStep(2)
                  }
                }}
              >
                Continua
              </Button>
            </div>
          </form>
        </div>
      )}
      {step === 2 && (
        <div
          sx={{
            m: `auto`,
            maxWidth: 600,
            width: `100%`,
          }}
        >
          <p>Completa ora il pagamento di</p>
          <h2 sx={{ color: `secondary` }}>&euro; {amount}</h2>
          {error !== "" && <ErrorMessage error={error} />}
          <PayPalButton
            style={{ shape: `pill`, label: `pay` }}
            options={{
              currency: "EUR",
              clientId: process.env.GATSBY_PAYPAL_CLIENT_ID,
              disableFunding: `mybank,sofort,credit,card`,
            }}
            onCancel={() => setDisable(false)}
            createOrder={() => {
              setDisable(true)
              return fetch("/.netlify/functions/create-transaction", {
                method: "post",
                headers: {
                  "content-type": "application/json",
                },
                body: JSON.stringify({
                  amount: amount,
                  firstName: firstName,
                  lastName: lastName,
                  address: address,
                  city: city,
                  zip: zip,
                  email: email,
                  pob: pob,
                  dob: dob,
                  cf: cf,
                  checkedPrivacy: checkedPrivacy,
                  checkedDeclaration: checkedDeclaration,
                }),
              })
                .then(function (res) {
                  return res.json()
                })
                .then(function (data) {
                  if (data.message) {
                    setError(data.message)
                    setDisable(false)
                    return
                  }
                  return data.orderID
                })
            }}
            onApprove={(data, actions) => {
              if (data.orderID) {
                return fetch("/.netlify/functions/capture-transaction", {
                  method: "post",
                  headers: {
                    "content-type": "application/json",
                  },
                  body: JSON.stringify({
                    orderID: data.orderID,
                    name: firstName,
                    amount: amount,
                    email: email,
                  }),
                })
                  .then(function (res) {
                    return res.json()
                  })
                  .then(function (orderData) {
                    const errorDetail =
                      Array.isArray(orderData.details) && orderData.details[0]

                    if (
                      errorDetail &&
                      errorDetail.issue === "INSTRUMENT_DECLINED"
                    ) {
                      return actions.restart()
                    }

                    if (errorDetail) {
                      setError(
                        "Il tuo pagamento non è stato processato. " +
                          errorDetail.description
                      )
                    }
                    setStep(3)
                  })
                  .catch(error => setError(error))
              }
            }}
          />
          <Button
            sx={{ variant: `button.default`, mx: `auto`, mt: 4 }}
            disabled={disabled}
            onClick={() => {
              setError("")
              setStep(1)
            }}
          >
            Torna indietro
          </Button>
        </div>
      )}
      {step === 3 && <h2>Grazie!</h2>}
    </div>
  )
}

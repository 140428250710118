/** @jsx jsx **/
import { jsx, Button } from "theme-ui"

const SelfDeclarationBanner = ({ setToggle, ...props }) => (
  <div
    sx={{
      mt: 3,
      bg: `red`,
      p: 4,
      borderRadius: `5px`,
    }}
    {...props}
  >
    <h3>Autodichiarazione ex L.3/2019</h3>
    <p sx={{ fontSize: 2 }}>
      Ai sensi e per gli effetti di cui all’art. 1, commi 11-27 della legge 9
      gennaio 2019, n. 3 dichiaro che:
    </p>
    <ol type="a">
      <li sx={{ fontSize: 2 }}>
        nel caso in cui il contributo da me erogato sia superiore ad Euro 500,00
        (cinquecento) nell'anno (unitariamente o cumulativamente), non sono
        contrario alla pubblicità dei miei dati personali, se necessaria ai fini
        della trasparenza e rendicontazione previste per legge;
      </li>
      <li sx={{ fontSize: 2 }}>
        sono iscritto nelle liste elettorali e ho il pieno godimento del diritto
        di voto;
      </li>
      <li sx={{ fontSize: 2 }}>
        non sono un Governo, un ente pubblico di uno Stato estero né una persona
        giuridica avente sede in uno Stato estero non assoggettata ad obblighi
        fiscali in Italia;
      </li>
      <li sx={{ fontSize: 2 }}>
        non sono un organo della pubblica amministrazione, un ente pubblico, una
        società con partecipazione di capitale pubblico superiore al 20 per
        cento, né una società controllata da queste ultime;
      </li>
      <li sx={{ fontSize: 2 }}>
        non sono una società con partecipazione di capitale pubblico pari o
        inferiore al 20 per cento, né una società controllata da quest'ultima,
        ove tale partecipazione assicuri al soggetto pubblico il controllo della
        società;
      </li>
      <li sx={{ fontSize: 2 }}>
        nel caso di società diverse da quelle di cui al superiore punto e): il
        finanziamento o il contributo è stato deliberato dall'organo sociale
        competente, è stato regolarmente iscritto in bilancio e non è vietato
        dalla legge;
      </li>
      <li sx={{ fontSize: 2 }}>
        non sono una cooperativa sociale, né un consorzio disciplinato dalla
        legge 8 novembre 1991, n. 381.
      </li>
    </ol>
    <Button
      sx={{ variant: `button.default`, mx: `auto` }}
      type="button"
      onClick={() => setToggle(false)}
    >
      Chiudi
    </Button>
  </div>
)

export default SelfDeclarationBanner
